import React from 'react';
import PropTypes from 'prop-types';
import './HomeContent.scss';

const HomeContent = props => (
	<div className="layer-home">
		{/* <div className="front-panel">
			<div className="welcome-title">Welcome To <br/>Vivek's
			</div>
		</div>
			{/* Second Front Panel is to place second information box lower. 
		<div className="front-panel">
			<div className="flex-placeholder"/>
				<div className="welcome-brief">
					Portfolio Page!!!
				</div>
			<div className="flex-placeholder"/>
		</div> */}
		<div className="flex-placeholder"></div>
		<div className='welcome-title'>Vivek Pranavamurthi</div>
		<div className="flex-placeholder"></div>
	</div>
);

// todo: Unless you need to use lifecycle methods or local state,
// write your component in functional form as above and delete
// this section. 
// class HomeContent extends React.Component {
//   render() {
//     return <div>This is a component called HomeContent.</div>;
//   }
// }

const HomeContentPropTypes = {
	// always use prop types!
};

HomeContent.propTypes = HomeContentPropTypes;

export default HomeContent;
