import {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import './ProductsCatalog.scss';
import ProductCategories from '../ProductCategories/ProductCategories';
import { publishedApps, videos, repositories } from '../../ProductList';




// todo: Unless you need to use lifecycle methods or local state,
// write your component in functional form as above and delete
// this section. 
const ProductsCatalog = (props) => {
	
	// console.log(props)
	// const [publishedApps, setAppclicked, appclicked] = props;x



	const [appclicked, setAppclicked] = useState(false)
	const [videoclicked, setVideoclicked] = useState(false)
	const [gitclicked, setGitclicked] = useState(false)


	useEffect (() => {
		console.log(props.globalClicked)
		if(!props.globalClicked)
		{
			setAppclicked(false)
			setVideoclicked(false)
			setGitclicked(false)
		}

	},[props.globalClicked])

	useEffect (() => {
		console.log(props.globalClicked)
		if(appclicked || videoclicked || gitclicked) {
			props.setGlobalClicked(true)
		}

	},[appclicked, videoclicked, gitclicked])


	return (
		<div className="product-categories" id="Products">
			
			<ProductCategories
				section = "Apps"
				productArray = {publishedApps}
				setClicked = {setAppclicked}
				clicked = {appclicked}
			/>

			<ProductCategories
				section = "Videos"
				productArray = {videos}
				setClicked = {setVideoclicked}
				clicked = {videoclicked}
			/>
					
			<ProductCategories
				section = "Github"
				productArray = {repositories}
				setClicked = {setGitclicked}
				clicked = {gitclicked}
			/>
		</div>
	)
	
}

const ProductsCatalogPropTypes = {
	// always use prop types!
};

ProductsCatalog.propTypes = ProductsCatalogPropTypes;

export default ProductsCatalog;
