import React from 'react';
import PropTypes from 'prop-types'
import './ProductCard.scss';



const logo = (name) => {
	let img;
	try {
		img = require('./../../Images/Logo-' + name +'.png')
	}
	catch(e) {
		img = require('./../../Images/sample.jpeg')
	}
	return img
} 


const ProductCard = props => (
	<div onClick = {() => !props.isDragging ? websiteDirect(props.productPage) : null}
	className="glossyContainer">

		<div className='image'> 
			<img 
				src={logo(props.product)}
			/>
		</div>
		<div className='title-holder'>
			<div className="title">{props.product}</div>
		</div>
		
	</div>

);

// todo: Unless you need to use lifecycle methods or local state,
// write your component in functional form as above and delete
// this section. 
// class ProductCard extends React.Component {
//   render() {
//     return <div>This is a component called ProductCard.</div>;
//   }
// }

const websiteDirect = (link) => {
	// if(props.isDragging)
	window.location.href = link;
}

const ProductCardPropTypes = {
	product: PropTypes.string,
	isDragging: PropTypes.bool,
	// isDragging: Boolean
	// always use prop types!
};


ProductCard.propTypes = ProductCardPropTypes;

export default ProductCard;
