import React, {useState, useEffect} from 'react';
import './ProductCategories.scss';
import ProductCard from '../ProductCard/ProductCard';


let mouseX;

function ProductCategories (props) {
	const [positionDif, setPositionDif] = useState(0)
	const [startX, setStartX] = useState(0)
	const [isDragging, setIsDragging] = useState(false);

	let maxScroll = 0

	useEffect(() => {
		if(!props.clicked) {

			// Check the Scroll Boundaries
			if(positionDif > 20) {
				setPositionDif(20)
				setStartX(20)
			}
			
			else if(positionDif < -1*maxScroll){
				setPositionDif(-maxScroll)
				setStartX(-maxScroll)
			}
			else {
				setStartX(positionDif)
			}


			// I think this is to stop the drag after click is released
			// With stylistic delay.
			setTimeout(() => {		
				setIsDragging(false);
			  }, 20);
		}
	  },
	  [props.clicked])
	const styles = { 
		transform: `translate(${positionDif}px, 0px)` 
	};

	// Mouse Effects
	const mouseMove = (event) => {
		if(props.clicked){
			console.log(`${event.clientX} ${mouseX} ${startX}`)
			setPositionDif(event.clientX - mouseX + startX)
			if(Math.abs(positionDif -startX) > 1) {
				setIsDragging(true)
			}
			
		}
	}
	const mouseDown = (event) => {
		mouseX = event.clientX
		props.setClicked(true)	
	}


	// Grab the Width to lock the scrolls
	const sectHoldExt = "-section-holder"
	let sectionHolder = document.getElementById(props.section + sectHoldExt)
	const sectExt = "-section"
	let section = document.getElementById(props.section + sectExt)
	if(section && sectionHolder) {
		maxScroll = section.scrollWidth - sectionHolder.offsetWidth + 200/2
		
	}


	// HTML
	return(
	<div className="panel">
		<div className="product-category-title">
			<h1>
				{props.section}
			</h1>
		</div>
			<div 
			id = {props.section+sectHoldExt}
			className="section-holder"
			onMouseDown={(e) => {mouseDown(e)}} 
			onMouseMove={(e) => {mouseMove(e)}}
			>
				<div 
				id = {props.section+sectExt}
				className="section" 
				style={styles} 
				>
					{postProductCards(props.productArray, isDragging)}
				</div>
			</div>
	</div>
	)
};

// Setup Post Product Cards Item.
const postProductCards = (productArray, dragStatus) => {
	const productRow = [];
	productArray.forEach((app) => {
		productRow.push(<ProductCard
		key={app.name}
		product={app.name}
		productPage={app.site}
		isDragging={dragStatus}
		/>);

	} );
	return productRow;
};

export default ProductCategories;
