import React from 'react';

import './HomeUX.scss';

const HomeUX = props => (
	<div className="layer-ux">
		<div className="next-image">
			<div className="image-stretcher">
				<a href="#Products">
					<img onClick={() => onClick} src="nextButton.png"></img>
				</a>
			</div>
		</div>
	</div>
);

// todo: Unless you need to use lifecycle methods or local state,
// write your component in functional form as above and delete
// this section. 
// class HomeUX extends React.Component {
//   render() {
//     return <div>This is a component called HomeUX.</div>;
//   }
// }

const onClick = () => {
	  console.log ('hello' )
}

const HomeUXPropTypes = {
	// always use prop types!
};

HomeUX.propTypes = HomeUXPropTypes;

export default HomeUX;
