import './App.css';
import { useState} from 'react';
import HomeUX from './components/HomeUX/HomeUX';
import HomeContent from './components/HomeContent/HomeContent';
import ProductsCatalog from './components/ProductsCatalog/ProductsCatalog';
// import spinVideo from 'https://vivekpwebsite.s3.amazonaws.com/movie_003.mp4'

function App() {
  const [globalClicked, setGlobalClicked] = useState(false)

  

  return (
    //Need to release Clicked on Mouse Up
    <div className="parallax" onMouseUp={() => setGlobalClicked(false)} onMouseLeave={()=> setGlobalClicked(false)}>
      <video loop="loop" muted="muted" autoPlay="autoplay" className="parallax-video">
        <source src="https://vivekpwebsite.s3.amazonaws.com/movie_003.mp4"/>
      </video>
      {/* <HomeContent/> */}
      <div className="scrollBox">
        {/* <a href="#test"> blah</a> */}
        <HomeContent />
        <ProductsCatalog
          globalClicked = {globalClicked}
          setGlobalClicked = {setGlobalClicked}
        />     
      </div>
      <HomeUX/>
    </div>

  );
}

export default App;
