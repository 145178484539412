export const publishedApps = 
[
    {
        name: 'ARocket Lander', 
        site:'https://apps.apple.com/af/app/arocket-lander/id1528416490?platform=iphone'
    },       
    {
        name:'AR Death Box', 
        site:'https://apps.apple.com/us/app/id1537334951'
    }
]

export const videos = [
    {
        name: 'ARocket Lander Teaser', 
        site: 'https://www.youtube.com/watch?v=JaY0Z1TZGjQ'
    }, 
    {
        name: 'Falcon Films Intro', 
        site: 'https://www.youtube.com/watch?v=uQGcyN_G3iA'
    }, 
    {
        name: 'USAFA 2018 Research Awards',
        site: 'https://www.facebook.com/USAFA.Official/videos/2018-research-awards/10155644560168853/'
    },
    {
        name: 'Steel Script Slammer', 
        site: 'https://www.youtube.com/watch?v=oE-kVKmM2G0'
    },
    {
        name: 'More Than A Game', 
        site: 'https://www.youtube.com/watch?v=lsgiuRoulTw'
    },
    {
        name: 'EndBall', 
        site: 'https://www.youtube.com/watch?v=pK-nq8bObFE'
    },
    {
        name: 'NCLS Closer', 
        site: 'https://www.youtube.com/watch?v=hwKeB7g0kvI'
    },
    {
        name: 'Remembering Jack Lindsey', 
        site: 'https://www.youtube.com/watch?v=7u00vkSXCW4'
    },
    {
        name: 'The Most Interesting Plebe', 
        site: 'https://www.youtube.com/watch?v=O6Mz4hz3290'
    },
    {
        name: 'B.O.B. Flys High', 
        site: 'https://youtu.be/4Z-r1lsAT_s'
    },
    {
        name: 'USAFA Football Spirit 2016', 
        site: 'https://www.youtube.com/watch?v=S7VcuopYjF8'
    },
    {
        name: 'USAFA Football Spirit 2017', 
        site: 'https://www.youtube.com/watch?v=R-p2UPKFThU'
    },
    {
        name: 'B.O.B. visits USAFA', 
        site: 'https://www.youtube.com/watch?v=dsX64JkJ464'
    },
    {
        name: 'Environmental Poem', 
        site: 'https://https://www.youtube.com/watch?v=H4UKZl4jKvs.com'
    },
    {
        name: 'Toy Drive Throwback', 
        site: 'https://youtu.be/blfbAo635u8'
    },
    {
        name: 'Anti-Bullying Campaign: Day 2', 
        site: 'https://youtu.be/AVBEs7Mjv90'
    },
    {
        name: 'Anti-Bullying Campaign: Day 4', 
        site: 'https://youtu.be/9UgWXtwEG1U'
    },
    {
        name: 'Anti-Bullying Campaign: Day 5', 
        site: 'https://youtu.be/EQ_RxQUNpJA'
    },
    {
        name: 'Anti-Bullying Campaign: Day 1', 
        site: 'https://youtu.be/T8Z_05qY6Lw'
    },
]

export const repositories = 
[
    {
        name: 'Sign Reader', 
        site:'https://github.com/Vivekprana/Sign-Reader'
    }, 
    {
        name: 'Solarist', 
        site: 'https://github.com/Vivekprana/Solarist'
    }, 
    {
        name:'Tabletop-Warfare', 
        site: 'https://github.com/Vivekprana/Tabletop-Warfare'
    },
    {
        name:'Rocket-Landing-Game', 
        site: 'https://github.com/Vivekprana/Rocket-Landing-Game'
    }
    
]